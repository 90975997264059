import React from 'react';


class Footer extends React.Component {
  render() {
    return (
      <footer
        className="mt-4"
        style={{
          // marginTop: rhythm(2.5),
          // paddingTop: rhythm(1),
        }}
      >
       
        {/* <div style={{ float: 'right' }}>
          <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
            rss
          </a>
        </div> */}
        {/* <a
          href="https://mobile.twitter.com/rwinsmit"
          target="_blank"
          rel="noopener noreferrer"
        >
          twitter
        </a>{' '}
        &bull;{' '}
        <a
          href="https://github.com/erwinsmit"
          target="_blank"
          rel="noopener noreferrer"
        >
          github
        </a>{' '} */}
      </footer>
    );
  }
}

export default Footer;
